import React, {Component, CSSProperties} from "react";
import styles from './myButton.module.scss';
import Link from "next/link";
import {sendMetrics} from "../../utils/misc";

export default class MyButton extends Component<any, any> {
  props: {
    text: string;
    subSaved?: boolean;
    subError?: boolean;
    disabled?: boolean;
    adjustHeight?: CSSProperties['height'];
    isSSR?: boolean;
    onClick?: (event?: any) => void;
    color?: string;
    withLink?: string;
    width?: string;
    metric?: string;
    containerWidth?: string;
    fontWeight?: CSSProperties['fontWeight'];
    padding?: CSSProperties['padding'];
    ml?: CSSProperties['marginLeft'];
    mr?: CSSProperties['marginRight'];
    fontSize?: CSSProperties['fontSize'];
    lineHeight?: CSSProperties['lineHeight'];
  }

  state: {
    loading: boolean;
  }
  private timer: NodeJS.Timer;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  clickButton = (event: any): void => {
    if (this.props.metric) {
      sendMetrics(this.props.metric)
    }
    this.setState({
      loading: true
    })
    this.timer = setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000)
    if (this.props.onClick && !this.state.loading) {
      this.props.onClick(event);
    }
  }

  preventClick = (e) => {
    e.preventDefault()
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }


  render() {
    const {props, state, clickButton, preventClick} = this;
    const {loading} = state;
    const {
      text,
      subError,
      subSaved,
      color = 'red',
      disabled,
      withLink = '',
      width = 'auto',
      fontWeight = 500,
      containerWidth = 'auto',
      adjustHeight = 'auto',
      padding = '15px 24px',
      ml = 0,
      fontSize = '16px',
      lineHeight = '20px',
      mr = 0,
      isSSR = false
    } = props;

    const btnStyle = {
      width: width,
      fontWeight: fontWeight,
      height: adjustHeight,
      padding: padding,
      marginLeft: ml,
      marginRight: mr,
      fontSize: fontSize,
      lineHeight: lineHeight
    };

    const btnClass = color === 'red' ? styles.MyButton_Button_Red : styles.MyButton_Button_Green;
    const btnContent = loading ? <img src='/static/images/icons/preloader.svg' alt={''}/> : text;
    return (
      <div className={styles.MyButton} style={{width: containerWidth}}>
        {!withLink &&
        <button
            style={btnStyle}
            onClick={!disabled ? clickButton : preventClick}
            className={btnClass}>
          {btnContent}</button>}

        {(withLink && isSSR) &&
        <Link href={withLink}>
            <a style={btnStyle}
               onClick={!disabled ? clickButton : preventClick}
               className={btnClass}>
              {btnContent}
            </a>
        </Link>}

        {(withLink && !isSSR) &&
        <a style={btnStyle}
           href={withLink}
           onClick={!disabled ? clickButton : preventClick}
           className={btnClass}>
          {btnContent}
        </a>}

        {subSaved &&
        <span className={styles.MyButton_Text_Success}>Изменения сохранены</span>}

        {subError &&
        <span className={styles.MyButton_Text_Error}>Ошибка сохранения</span>}

      </div>
    )
  }
}
