import React, { Component } from "react";
import styles from "./detailBloodStationNeeds.module.scss";
import { BLOOD_GROUPS, VERY_SHORT_BLOOD_GROUPS } from "../../../entities";
import { inject, observer } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";

@inject("authStore")
@observer
export default class DetailBloodStationNeeds extends Component<any, any> {
  props: {
    needs: string[];
    authStore?: AuthStore;
    noTitle?: boolean;
    data?: any;
    status?: string;
  };
  state: {
    isSelectedGroup: boolean;
    selectedGroup: string;
    isSelectedNeed: boolean;
  };

  constructor(props) {
    super(props);
    this.state = {
      isSelectedGroup: false,
      selectedGroup: null,
      isSelectedNeed: false,
    };
  }

  checkForNullBloodGroup = (blood_group: string): boolean => {
    if (blood_group === "dont_know") return false;
    if (blood_group === null) return false;
    return true;
  };

  componentDidMount() {
    if (this.props.authStore?.user)
      this.setState({
        isSelectedGroup: this.checkForNullBloodGroup(
          this.props.authStore?.user?.blood_group
        ),
        selectedGroup:
          this.props.authStore?.user &&
          this.props.authStore?.user?.blood_group !== "dont_know"
            ? this.props.authStore?.user?.blood_group
            : null,
        isSelectedNeed: this.props.authStore?.user?.blood_group
          ? this.getInfoAboutNeeded(this.props.authStore.user.blood_group)
          : null,
      });
  }

  getInfoAboutNeeded = (key) => {
    if (this.props.needs)
      return !!this.props.needs.find((item) => {
        return item === key;
      });
    else return null;
  };

  setActiveNeed = (e) => {
    this.setState({
      isSelectedGroup: true,
      selectedGroup: e,
      isSelectedNeed: this.getInfoAboutNeeded(e),
    });
  };

  render() {
    const { props, state, setActiveNeed, getInfoAboutNeeded } = this;
    const { isSelectedGroup, selectedGroup, isSelectedNeed } = state;
    const { authStore, noTitle, data, status } = props;
    return (
      <div className={styles.DetailBloodStationNeeds}>
        <div className={styles.DetailBloodStationNeeds_City}>
          {data && [data?.city.title, data?.address].join(", ")}
        </div>
        <div className={styles.DetailBloodStationNeeds_Title}>
          <div className={styles.DetailBloodStationNeeds_Title_Info}>
            {!noTitle && <h3>Потребности</h3>}
            {authStore?.user?.blood_group &&
              authStore?.user?.blood_group !== "dont_know" && (
                <span>
                  Ваша группа —{" "}
                  {VERY_SHORT_BLOOD_GROUPS[authStore?.user?.blood_group]}
                </span>
              )}
          </div>
          {status !== "unknown" && (
            <div className={styles.DetailBloodStationNeeds_Title_Data}>
              <div className={styles.DetailBloodStationNeeds_Title_Data_Red}>
                <span>Требуется</span>
                <div />
              </div>
              <div className={styles.DetailBloodStationNeeds_Title_Data_Green}>
                <span>Достаточно</span>
                <div />
              </div>
            </div>
          )}
        </div>
        <div
          className={styles.DetailBloodStationNeeds_Body}
          onClick={(e) => e.stopPropagation()}
        >
          {status !== "unknown" && (
            <div className={styles.DetailBloodStationNeeds_Body_Plats}>
              {Object.keys(VERY_SHORT_BLOOD_GROUPS).map((item) => {
                return (
                  <NeedsItem
                    selectedGroup={selectedGroup}
                    getInfoAboutNeeded={getInfoAboutNeeded}
                    setActiveNeed={setActiveNeed}
                    info={item}
                  />
                );
              })}
            </div>
          )}
          {status === "unknown" && (
            <div className={styles.DetailBloodStationNeeds_Body_NoNeeds}>
              <div>
                <img src="/static/images/icons/NotFoundedMain.svg" alt="" />
              </div>
              <span>Данные отсутствуют</span>
            </div>
          )}
          {isSelectedGroup && status !== "unknown" && (
            <div className={styles.DetailBloodStationNeeds_Body_GroupSelect}>
              <span>{BLOOD_GROUPS[selectedGroup]}</span>
              {isSelectedNeed && <p>Готовы принять с данной группой крови</p>}
              {!isSelectedNeed && <p>Запасов крови данной группы достаточно</p>}
            </div>
          )}
          {status === "unknown"  && (
            <div className={styles.DetailBloodStationNeeds_Body_GroupSelect}>
              <p>Уточните потребность по телефону</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const NeedsItem = ({
  info,
  setActiveNeed,
  getInfoAboutNeeded,
  selectedGroup,
}) => {
  const isNeeded = getInfoAboutNeeded(info);
  return (
    <div
      onClick={() => {
        setActiveNeed(info);
      }}
      data-focused={selectedGroup === info}
      className={isNeeded ? styles.NeedsItemRed : styles.NeedsItemGreen}
    >
      <div>
        <span>{VERY_SHORT_BLOOD_GROUPS[info]}</span>
      </div>
    </div>
  );
};
