import React from 'react';
import styles from './notFoundedCard.module.scss';
import Link from 'next/link'


const NotFoundedCard = ({text = '', subText = '', slugLink}) => {
  return (
    <Link href={slugLink ? `/bloodstations/${slugLink}/` : '/bloodstations/'}>
      <div className={styles.NotFoundedCard}>
        <div className={styles.NotFoundedCard_Block}>
          <div className={styles.NotFoundedCard_Block_Image}>
            <img src="/static/images/icons/NotFoundedMain.svg" alt=""/>
          </div>
          <span>{text}</span>
          <p>{subText}</p>
        </div>
      </div>
    </Link>
  )
}

export default NotFoundedCard
